import React, { createContext, useState, useEffect, useContext } from 'react';

const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
  const [conversionRates, setConversionRates] = useState({ $: 1 });

  // Function to fetch conversion rates from the API
  const fetchConversionRate = async (currency) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({ currency });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(`${REACT_APP_BASE_URL}/get_currency`, requestOptions);
      const result = await response.json();

      if (result.Status === "1" && result.currencies) {
        const { price } = result.currencies;
        setConversionRates((prevRates) => ({
          ...prevRates,
          [currency]: parseFloat(price),
        }));
        localStorage.setItem('selectedCurrency', result.currencies.currency)

        // console.log(`Fetched rate for ${currency}: ${price}`);
      } else {
        console.error("Failed to fetch currency rates:", result.Msg);
      }
    } catch (error) {
      console.error("Error fetching conversion rate:", error);
    }
  };



  return (
    <CurrencyContext.Provider value={{ conversionRates, fetchConversionRate }}>
      {children}
    </CurrencyContext.Provider>
  );
};

// Custom hook to use the CurrencyContext
export const useCurrency = () => {
  return useContext(CurrencyContext);
};
