import React, { useState, useEffect } from 'react';
import Modal from "@mui/material/Modal";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./PaymentSuccess.css";
import paymentSuccessGif from '../../Assets/Business_section.png'
import SuccessIcon from '../../Assets/check.png'
import { Bars } from 'react-loader-spinner';

//These imports are for the reports download
import jsPDF from "jspdf";
import "jspdf-autotable";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#FFFFFF"
};

const PaymentSuccess = () => {
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const [openBox, setOpenBox] = useState(true);
    const navigate = useNavigate();

    const CheckPlanCategory = localStorage.getItem("CheckPlanCategory")  // To check whether it is regional or country plan.

    const [billingListShowLoader, setBillingListShowLoader] = useState(false);
    const [billingPrice, setBillingPrice] = useState("");
    const [billingCurrency, setBillingCurrency] = useState("");
    const [billingPlanName, setBillingPlanName] = useState("");
    const [billingSuccessPayment, setBillingSuccessPayment] = useState("");
    const [billingTransactionId, setBillingTransactionId] = useState("");
    const [billingEmail, setBillingEmail] = useState("");
    const [billingPaymentType, setBillingPaymentType] = useState("");

    // getting the getpayment resposne status
    const [AllDataResponse, setAllDataResponse] = useState('')

    const UserUuid = localStorage.getItem("UserUuid");

    const BillingCountryName = localStorage.getItem("BillingCountryName");
    const regionName = localStorage.getItem("RegionName") || ""; // Fallback to empty string if null
    const BillingRegionName = regionName.charAt(0).toUpperCase() + regionName.slice(1).toLowerCase();
    // const BillingRegionName = localStorage.getItem("RegionName").charAt(0).toUpperCase() + localStorage.getItem("RegionName").slice(1).toLowerCase()

    const BillingPlan = localStorage.getItem("BillingPlan");
    const BillingDays = localStorage.getItem("BillingDays");
    const BillingData = localStorage.getItem("BillingData");
    const BillingPayment = localStorage.getItem("BillingPrice");
    const PlanType = localStorage.getItem("PlanType")
    const BillingUID = localStorage.getItem("BillingUid");

    const BillingCountryCode = localStorage.getItem("BillingCountryCode");
    const BillingRegionCode = localStorage.getItem("BillingRegionCode");

    const countryData = require('country-data');

    // const handleCreatePlan = (paymentDetailsId, iccid) => {
    //     const myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");

    //     const raw = JSON.stringify({
    //         "planname": BillingPlan,
    //         "user_uuid": UserUuid,
    //         "days": BillingDays,
    //         "country": CheckPlanCategory == "RegionPlan" ? BillingRegionName : BillingCountryName,
    //         "order": paymentDetailsId,
    //         "data": BillingData,
    //         "price": BillingPayment,
    //         "plan_type": PlanType,
    //         "region": "",
    //     });

    //     const requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: "follow"
    //     };

    //     fetch(`${REACT_APP_BASE_URL}/create_plan/${iccid}/${BillingUID}`, requestOptions)
    //         .then((response) => response.json())
    //         .then((result) => {
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // }

    const handleChooseIccidApi = (ChooseIccids) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "user_uuid": UserUuid,
            "iccid": ChooseIccids
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_URL}/choose_iccid`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                // console.log(result)
            })
            .catch((error) => console.error(error));
    }

    // const showBillingData = async () => {
    //     const myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");


    //     const requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         redirect: "follow"
    //     };

    //     try {
    //         const response = await fetch(`${REACT_APP_BASE_URL}/get_payment_details?user_uuid=${UserUuid}`, requestOptions);

    //         if (!response.ok) { throw new Error('Network response was not ok'); }

    //         const result = await response.json();

    //         setBillingListShowLoader(true);
    //         setBillingPrice(result.session_url.price);
    //         setBillingPlanName(result.session_url.plan_name);
    //         setBillingSuccessPayment(result.payment_details.status);
    //         setBillingTransactionId(result.payment_details.id);
    //         setBillingEmail(result.payment_details.billing_details.email);
    //         setBillingPaymentType(result.payment_details.payment_methods.type);
    //         setAllDataResponse(result.status);

    //         let iccid = localStorage.getItem('ICCID');

    //         if (!iccid || iccid == "") {
    //             const apiHeaders = new Headers();
    //             apiHeaders.append("Content-Type", "application/json");

    //             const country = countryData.countries[BillingCountryCode];
    //             const CountryName = country ? country.name : "Unknown country code";
    //             const iso2Country = country ? country.alpha2 : "";

    //             const apiRaw = JSON.stringify({
    //                 "tag": "",
    //                 "region": CheckPlanCategory == "RegionPlan" ? BillingRegionCode : iso2Country.trim(),
    //                 "country": CheckPlanCategory == "RegionPlan" ? BillingRegionName : CountryName,
    //                 "days": BillingDays,
    //                 "data": BillingData,
    //             });

    //             const apiRequestOptions = {
    //                 method: "POST",
    //                 headers: apiHeaders,
    //                 body: apiRaw,
    //                 redirect: "follow"
    //             };

    //             const esimResponse = await fetch(`${REACT_APP_BASE_URL}/create_esim?user_uuid=${UserUuid}`, apiRequestOptions);
    //             const esimResult = await esimResponse.json();

    //             localStorage.setItem("ICCID", esimResult.esim.iccid);
    //             localStorage.setItem("CheckFirstTimeIccid", esimResult.esim.iccid);

    //             handleCreatePlan(result.payment_details.id, esimResult.esim.iccid);
    //             handleChooseIccidApi(esimResult.esim.iccid);

    //         } else {
    //             handleCreatePlan(result.payment_details.id, iccid);
    //             handleChooseIccidApi(iccid)
    //         }
    //     } catch (error) {
    //         console.error('There was a problem with the fetch operation:', error);
    //     }
    // };



    let iccid = localStorage.getItem('ICCID');

    const showBillingData = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const country = countryData.countries[BillingCountryCode];
        const CountryName = country ? country.name : "Unknown country code";
        const iso2Country = country ? country.alpha2 : "";

        const raw = JSON.stringify(

            {
                "user_uuid": UserUuid,
                "region": CheckPlanCategory == "RegionPlan" ? BillingRegionCode : iso2Country.trim(),
                "country": CheckPlanCategory == "RegionPlan" ? BillingRegionName : CountryName,
                "days": BillingDays,
                "data": BillingData,
                "iccid": iccid ? iccid : "",
                "plantype_id": BillingUID,
                "plan_name": BillingPlan,
                "price": BillingPayment,
                "plan_type": PlanType
            }
        );

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",

        };



        try {



            const response = await fetch(`${REACT_APP_BASE_URL}/get_payment_details?user_uuid=${UserUuid}`, requestOptions);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            setBillingListShowLoader(true);
            setBillingPrice(result.session_url.price);
            setBillingCurrency(result.session_url.currency);
            setBillingPlanName(result.session_url.plan_name);
            setBillingSuccessPayment(result.payment_details.status);
            setBillingTransactionId(result.payment_details.id);
            setBillingEmail(result.payment_details.billing_details.email);
            setBillingPaymentType(result.payment_details.payment_methods.type);
            setAllDataResponse(result.status == 1 || 2);
            handleChooseIccidApi(result.data.iccid);

        } catch (error) {
            setBillingListShowLoader(false);
            console.error("Error fetching billing data:", error);
        }
    };



    useEffect(() => {
        if (openBox) {
            setTimeout(() => {
                showBillingData();
            }, 1000);
        }
    }, []);

    const generatePDF = () => {
        const report = new jsPDF("portrait", "mm", "a4");

        const data = [];
        const headers = ['Field', 'Value'];

        const tableData = document.querySelectorAll('.payment_detail p');
        tableData.forEach(row => {
            const field = row.childNodes[0].textContent.trim();
            const value = row.childNodes[1].textContent.trim();
            data.push([field, value]);
        });

        report.autoTable({
            head: [headers],
            body: data,
            theme: "striped",
            styles: { fontSize: 8 },
            margin: { top: 15 },
        });
        report.save("Payment_Success_Details.pdf");
    };

    const handleHomenavigate = () => {
        navigate('/');
        localStorage.removeItem("BillingCountryCode")
        localStorage.removeItem("BillingDays")
        localStorage.removeItem("BillingData")
        localStorage.removeItem("BillingPrice")
        localStorage.removeItem("BillingPlan")
        localStorage.removeItem("BillingUid")
        localStorage.removeItem("PlanType")
        localStorage.removeItem("BillingRegionCode")

        localStorage.removeItem("PlanUID")
        localStorage.removeItem("RegionName")
        localStorage.removeItem("ICCID")
    }

    return (
        <>
            <Modal open={openBox}>
                <Box sx={style} className="SuccessPaymentModalStyling">
                    <img src={paymentSuccessGif} className="success_gif" alt="Payment Success" />
                    {billingListShowLoader ? (
                        <div className="paymnet_main">
                            <div className="payment_detail" id="Table-Data">
                                <h2>Payment Successful    <img src={SuccessIcon} className='SuccessIcon' alt="Sucess icon" /></h2>

                                <hr />
                                {AllDataResponse === 1 &&

                                    <>
                                        <p>
                                            Transaction Id :
                                            <span> {billingTransactionId}</span>

                                        </p>
                                        <p>
                                            Payment Type :
                                            <span style={{ textTransform: 'capitalize' }}> {billingPaymentType} </span>
                                        </p>
                                        <p>
                                            Email ID :
                                            <span> {billingEmail} </span>
                                        </p>
                                    </>

                                }
                                <p>
                                    Package Name :
                                    <span> {billingPlanName}</span>
                                </p>
                                <p>
                                    Amount :
                                    <span>
                                        {billingCurrency === 'eur' ? `€ ${billingPrice}` :
                                            billingCurrency === 'usd' ? `$ ${billingPrice}` :
                                                billingCurrency === 'gbp' ? `£ ${billingPrice}` : ''}
                                    </span>
                                </p>
                                <p>
                                    Status Payment :
                                    <span style={{ textTransform: 'capitalize' }}> {billingSuccessPayment}</span>
                                </p>
                            </div>

                            <div style={{
                                display: "flex",
                                padding: "15px 0px",
                                width: '50%',
                                justifyContent: 'space-between'
                            }}>
                                <Button
                                    style={{ color: "Green", transform: "scale(.9)" }}
                                    variant="outlined"
                                    color="inherit"
                                    onClick={generatePDF}
                                >
                                    Print
                                </Button>

                                <Button
                                    style={{ color: "red", transform: "scale(.9)" }}
                                    variant="outlined"
                                    color="inherit"
                                    onClick={handleHomenavigate}
                                >
                                    Close
                                </Button>
                            </div>
                            <p> You will receive email instructions.
                                Please also check your junk or spam folder if you don't see it in your inbox.</p>
                        </div>
                    ) : (
                        <div style={{
                            width: '100%',
                            height: '20vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Bars
                                height="30"
                                width="30"
                                color="#37BAE4"
                                visible={true}
                            />
                        </div>
                    )}
                </Box>
            </Modal>
        </>
    );
};

export default PaymentSuccess;
