import React, { useState } from 'react';
import './Login.css';
import Logo from '../../Assets/digiesim.png'
import AppleLogo from '../../Assets/AppleLogo.png'
import FbLogo from '../../Assets/FbLogo.png'
import GoogleLogo from '../../Assets/GoogleLogo.png'
import { Link } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { H2, H1, H3 } from "../Typography";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
    styled,
    Button,
    FormControl,
    Switch,
    FormGroup,
    FormControlLabel, Radio,
    TextField,
    Checkbox,
    Box,
    OutlinedInput,
} from '@mui/material';
import { Label } from '@mui/icons-material';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import OtpTimer from 'otp-timer'
import Modal from "@mui/material/Modal";

const ModalStyle2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 550,
    bgcolor: "white",
    boxShadow: 24,
    borderRadius: "10px",
    border: "0px",
    p: 3,
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#b2efb3",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

const Login = () => {
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate()
    const [SwitchTab, setSwitchTab] = useState("1");

    const customId = "forNotShowingMultipleToast"

    // getting the time in epoch milisecond
    const CurrTime = Date.now()

    // console.log(CurrTime, 'currTimeeeeeee');

    const HandleSwitch = (value) => {
        setSwitchTab(value)

        setEmail("")
        setEmailError("")
        setPassword("")
        setPasswordError("")
        setShowLoginPassword(false)

        setFirstName("")
        setFirstNameError("")
        setLastName("")
        setSignUpEmail("")
        setSignupEmailError("")
        setSignUpPassword("")
        setShowSigninPassword("")
        setSignupPasswordError("")
        setRetypeSignUpPassword("")
        setRetypeSignupPasswordError("")
        setShowRetypePassword("")
        setReferral("")
        setPromotionMail(false)


    }

    const togglePasswordVisibility = (value, SetValue) => {
        SetValue(!value);

    };

    // For the Login Page States
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState('');
    const [emailFlag, setEmailFlag] = useState(true);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [Remember, setRemember] = useState(true)
    const [ShowLoginPassword, setShowLoginPassword] = useState(false);



    // For the Sign up Page States
    const [FirstName, setFirstName] = useState("");
    const [FirstNameError, setFirstNameError] = useState('');
    const [FirstNameFlag, setFirstNameFlag] = useState(true);
    const [LastName, setLastName] = useState("")
    const [SignUpEmail, setSignUpEmail] = useState("");
    const [SignupEmailError, setSignupEmailError] = useState('');
    const [SignupEmailFlag, setSignupEmailFlag] = useState(true);
    const [SignUpPassword, setSignUpPassword] = useState("")
    const [ShowSigninPassword, setShowSigninPassword] = useState(false);
    const [SignupPasswordFlag, setSignupPasswordFlag] = useState(true);
    const [SignupPasswordError, setSignupPasswordError] = useState('');
    const [RetypeSignUpPassword, setRetypeSignUpPassword] = useState("");
    const [RetypeSignupPasswordError, setRetypeSignupPasswordError] = useState('');
    const [RetypeSignupPasswordFlag, setRetypeSignupPasswordFlag] = useState(true);
    const [ShowRetypePassword, setShowRetypePassword] = useState(false);
    const [Referral, setReferral] = useState("")
    const [PromotionMail, setPromotionMail] = useState(false)  // This is for checkbox to recieve promotion mails.

    const [OpenVerifyEmailModal, setOpenVerifyEmailModal] = useState(false)  // This state is for opening Verify Email Modal.
    const SignUpEmailForOtpVerify = localStorage.getItem("SignUpEmailForOtpVerify")
    const [VerifyOtp, setVerifyOtp] = useState('');

    // verify email button showing state 
    const [VerifyEmailButton, setVerifyEmailButton] = useState(false);

    const redirectPath = localStorage.getItem("redirectAfterLogin");  // Path when buying withour login

    const HandleLogin = (e) => {

        if (!redirectPath) {
            localStorage.clear()
        }

        e.preventDefault();

        if (!email || !password) {
            toast.warn("Please fill in all required fields", {
                position: "top-right",
                toastId: customId,
                autoClose: 3000,
            });
            return;
        }
        if (!emailFlag) {
            toast.warn("Please enter a valid email address", {
                position: "top-right",
                toastId: customId,
                autoClose: 3000,
            });
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "email_id": email,
            "password": password,
            "login_time": CurrTime.toString()
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_URL}/login`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "1") {
                    const { iccid, first_name, last_name, email_id, user_uuid, plan_id ,login_time, auth_token,refresh_token} = result.accounts;
                    localStorage.setItem('FirstName', first_name);
                    localStorage.setItem('LastName', last_name);
                    localStorage.setItem('EmailId', email_id);
                    localStorage.setItem('UserUuid', user_uuid);
                    localStorage.setItem("CheckFirstTimeIccid", iccid)
                    localStorage.setItem("isLoggedIn", true);
                    localStorage.setItem("AccessToken", auth_token);
                    localStorage.setItem("RefreshToken", refresh_token);

                    // const storedICCID = localStorage.getItem("ICCID");
                    // if (iccid && iccid !== storedICCID) {
                    //     localStorage.setItem("ICCID", iccid);
                    // }

                    toast.success(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });

                    // Handle redirection
                    if (redirectPath) {
                        localStorage.removeItem("redirectAfterLogin");
                        navigate(redirectPath);
                    } else {
                        navigate('/');
                    }
                } else {
                    toast.error(result.Msg, {
                        position: "top-right",
                        autoClose: 2000,
                        toastid: customId
                    });

                    if (result.Status === "2") {
                        setVerifyEmailButton(true);
                        return;
                    }
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                toast("An error occurred. Please try again.", {
                    position: "top-right",
                    autoClose: 2000,
                });
            });
    };



    const HandleSignUp = (e) => {

        e.preventDefault();

        if (!FirstName || !LastName || !SignUpEmail || !SignUpPassword || !RetypeSignUpPassword) {
            toast.warn("Please fill in all required fields", {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
            return;
        }
        if (!FirstNameFlag) {
            toast.warn("Please enter first name", {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            })
        }
        if (!SignupEmailFlag) {
            toast.warn("Please enter valid email address", {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
            return;
        }
        if (!SignupPasswordFlag) {
            toast.warn("Please enter valid password", {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
            return;
        }
        if (!RetypeSignupPasswordFlag) {
            toast.warn("Please enter valid password", {
                position: "top-right",
                autoClose: 2000,
                toastId: customId
            });
            return;
        }

        if (SignUpPassword != RetypeSignUpPassword) {
            toast.warn("Password and Retype Password do not match.", {
                position: "top-right",
                autoClose: 2000,
                toastId: customId
            });
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "first_name": FirstName,
            "last_name": LastName,
            "email_id": SignUpEmail,
            "password": SignUpPassword,
            "referral": Referral
        });

        localStorage.setItem('SignUpEmailForOtpVerify', SignUpEmail)

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };


        fetch(`${REACT_APP_BASE_URL}/signup`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "1") {
                    setOpenVerifyEmailModal(true)
                         // Call `sendEmailToFirstPromoter` after a successful signup
                sendEmailToFirstPromoter();
                } else {
                    toast.success(result.Msg, {
                        position: "top-right",
                        autoClose: 2000,
                        toastId: customId
                    });
                    return;
                }
            })
    }
    
    const sendEmailToFirstPromoter = () => {
        window.fpr("referral", { email: SignUpEmail });
    };
    const HandleVerifyEmail = (emailId) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "email_id": emailId,
            "otp": VerifyOtp
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_URL}/verify_email`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "1") {
                    toast.success(result.Msg, {
                        position: "top-right",
                        autoClose: 3000,
                        toastId: customId
                    });

                    if (SwitchTab == "2") {
                        localStorage.removeItem('SignUpEmailForOtpVerify')
                    }

                    setVerifyOtp("")
                    setOpenVerifyEmailModal(false)
                    setSwitchTab('1')
                    setVerifyEmailButton(false);
                } else {
                    toast.error(result.Msg, {
                        position: "top-right",
                        autoClose: 3000,
                        toastId: customId
                    });
                    return;
                }
            })
    }

    const HandleResendOtp = (emailId) => {
        setOpenVerifyEmailModal(true);


        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "email_id": emailId
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_URL}/resend_otp`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status == "1") {
                    toast.success(result.Msg, {
                        position: "top-right",
                        autoClose: 3000,
                        toastId: customId
                    });
                }
                setVerifyOtp('')
            })
    }

    // Login page validation start here
    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        validateEmail(newEmail);

    }

    const validateEmail = (email) => {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

        if (!email) {
            setEmailError("Please enter your email id");
            setEmailFlag(false);
        } else if (!emailPattern.test(email)) {
            setEmailError("Invalid email address");
            setEmailFlag(false);
        } else {
            setEmailError("");  // Clear any previous error
            setEmailFlag(true); // Set flag to true for valid email
        }
    }

    const validatePassword = (password) => {
        if (!password) {
            setPasswordError("Please Enter Your Password");
        } else {
            setPasswordError("");
        }
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        validatePassword(newPassword);
    };


    // Sign up page validation start here 

    const handleFirstName = (e) => {
        const inputValue = e.target.value;
        setFirstName(inputValue);

        // If the input value is blank 
        if (inputValue.trim() === '') {
            setFirstNameError('Please enter first Name');
            setFirstNameFlag(false);
        } else {
            // Remove non-alphabetic characters
            const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, "");
            setFirstName(alphabeticValue);
            setFirstNameError('');
            setFirstNameFlag(true);

        }
    }
    const handleLastName = (e) => {
        const LastNameValue = e.target.value;
        const alphabeticValue = LastNameValue.replace(/[^a-zA-Z\s]/g, "");
        setLastName(alphabeticValue);
        //         setFirstNameError('');
        //         setFirstNameFlag(true); 
    }

    const handleSignUpEmailChange = (e) => {
        const SignUpEmail = e.target.value;
        setSignUpEmail(SignUpEmail);
        ValidateSignupEmail(SignUpEmail);
    }

    const ValidateSignupEmail = (email) => {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

        if (!email) {
            setSignupEmailError("Please enter your email id");
            setSignupEmailFlag(false);
        } else if (!emailPattern.test(email)) {
            setSignupEmailError("Invalid email address");
            setSignupEmailFlag(false);
        } else {
            setSignupEmailError("");  // Clear any previous error
            setSignupEmailFlag(true); // Set flag to true for valid email
        }
    }

    const maxPasswordLength = 16;

    const handleSignUpPassword = (e) => {
        const signuppasswordValue = e.target.value;

        if (signuppasswordValue.length <= maxPasswordLength) {
            setSignUpPassword(signuppasswordValue);
        }

        if (signuppasswordValue.length < 6 || signuppasswordValue.length > maxPasswordLength) {
            setSignupPasswordFlag(false);
            setSignupPasswordError("Password should be between 6 to " + maxPasswordLength + " characters")
        } else {
            setSignUpPassword(signuppasswordValue);
            setSignupPasswordError('');
            setSignupPasswordFlag(true);
        }
    }

    const handleRetypeSignupPassword = (e) => {
        const RetypesignuppasswordValue = e.target.value;

        if (RetypesignuppasswordValue.length <= maxPasswordLength) {
            setRetypeSignUpPassword(RetypesignuppasswordValue);
        }

        if (RetypesignuppasswordValue.length < 6 || RetypesignuppasswordValue.length > maxPasswordLength) {
            setRetypeSignupPasswordFlag(false);
            setRetypeSignupPasswordError("Password should be between 6 to " + maxPasswordLength + " characters")
        } else {
            setRetypeSignUpPassword(RetypesignuppasswordValue);
            setRetypeSignupPasswordError('');
            setRetypeSignupPasswordFlag(true);
        }
    }



    return (
        <div className='LoginPage' >
            <div className='LoginTop' >
                <Link to='/' > <img src={Logo} id="LoginPageLogo" /></Link>
            </div>

            <div className='LoginBottom' >
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', border: '1px rgb(92, 167, 224) solid', borderRadius: '15px', padding: '3px 4px' }} >
                    <p id='LoginTabs' onClick={() => HandleSwitch("1")} style={{
                        backgroundColor: SwitchTab == "2" && 'white',
                        color: SwitchTab == "2" ? 'grey' : 'white'
                    }} >Login</p>
                    <p id='LoginTabs' onClick={() => HandleSwitch("2")} style={{
                        backgroundColor: SwitchTab == "1" && 'white',
                        color: SwitchTab == "1" ? 'grey' : 'white'
                    }}>Sign Up</p>
                </div>

                <div className='content' >
                    {
                        SwitchTab == "1" &&
                        <div id="login_card">
                            <form id="login_data" onSubmit={HandleLogin}>
                                <>
                                    <TextField
                                        fullWidth
                                        label="Email Id"
                                        variant="outlined"
                                        inputProps={{ tabIndex: "1" }}
                                        value={email}
                                        // onChange={(e) => setEmail(e.target.value)}
                                        onChange={handleEmailChange}
                                        style={{ margin: '5px 0px' }}
                                    />
                                    {emailError && (
                                        <div style={{ color: "red", fontSize: "14px" }}>
                                            {emailError}
                                        </div>
                                    )}

                                </>

                                <>
                                    <div
                                        style={{
                                            position: "relative",
                                            width: "100%",

                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            type={ShowLoginPassword ? "text" : "password"}
                                            label="Password"
                                            variant="outlined"
                                            inputProps={{ tabIndex: "2" }}
                                            value={password}
                                            // onChange={(e) => setPassword(e.target.value)}
                                            onChange={handlePasswordChange}
                                            style={{ margin: '10px 0px', }}
                                        />

                                        <span
                                            onClick={() => togglePasswordVisibility(ShowLoginPassword, setShowLoginPassword)}
                                            style={{
                                                cursor: "pointer",
                                                position: "absolute",
                                                top: "40%",
                                                right: "5%",


                                            }}
                                        >
                                            {ShowLoginPassword ? <FaEye /> : <FaEyeSlash />}
                                        </span>
                                    </div>
                                    {passwordError && (
                                        <p style={{ color: "red", fontSize: "14px" }}>
                                            {passwordError}
                                        </p>
                                    )}


                                </>

                                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: '10px' }} >
                                    <FormControl component="fieldset">
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <IOSSwitch
                                                        name="Remember Me"
                                                        checked={Remember}
                                                        onChange={() => {
                                                            setRemember(!Remember);
                                                        }}
                                                    />
                                                }
                                                label={
                                                    <Typography style={{ fontSize: '12px', marginLeft: '6px' }}>
                                                        Remember Me
                                                    </Typography>
                                                } />
                                        </FormGroup>
                                    </FormControl>

                                    <p style={{
                                        color: "#5CA7E0",
                                        fontSize: "14px",
                                        textDecoration: "underline",
                                        cursor: 'pointer'
                                    }} onClick={() => { navigate('/forgot_password') }} >
                                        Forgot password?
                                    </p>
                                </div>

                                <Button
                                    onClick={HandleLogin}
                                    type="submit"
                                    style={{
                                        width: "95%",
                                        fontSize: "15px",
                                        color: "white",
                                        backgroundColor: "#5CA7E0",
                                        borderRadius: "10px",
                                        margin: '10px 0px ',
                                    }}
                                >
                                    Log In
                                </Button>

                                {VerifyEmailButton && (
                                    <Button
                                        onClick={(emailId) => HandleResendOtp(email)}
                                        // type="submit"
                                        style={{
                                            width: "95%",
                                            fontSize: "15px",
                                            color: "black",
                                            border: "1px solid #011015",
                                            backgroundColor: "#90e0ef",
                                            border: "0px ",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        Verify Email
                                    </Button>
                                )}
                            </form>


                            {/* <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%', margin: '5px 0px' }} >
                                <hr style={{ border: '1px lightgrey solid', width: '30%' }} />
                                <p style={{
                                    color: "#8224e3",
                                    fontSize: "13px",
                                    color: 'black',
                                    fontWeight: '100'
                                }} >
                                    OR LOGIN WITH
                                </p>
                                <hr style={{ border: '1px lightgrey solid', width: '30%' }} />
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', padding: '15px 0px' }} >
                                <img src={AppleLogo} className='logos' />
                                <img src={GoogleLogo} className='logos' />
                                <img src={FbLogo} className='logos' />
                            </div> */}

                        </div>
                    }

                    {
                        SwitchTab == "2" &&
                        <div id="login_card">
                            <form id="login_data" onSubmit={HandleSignUp}>
                                <>

                                    <TextField
                                        fullWidth
                                        label="First Name"
                                        variant="outlined"
                                        inputProps={{ tabIndex: "1" }}
                                        value={FirstName}
                                        // onChange={(e) => setFirstName(e.target.value)}
                                        onChange={handleFirstName}
                                        style={{ margin: '8px 0px' }}
                                    />
                                    {FirstNameError && (
                                        <p
                                            style={{
                                                color: "red",
                                                fontSize: "13px",
                                                margin: "0px 0 0 0px",
                                            }}
                                        >
                                            {FirstNameError}
                                        </p>
                                    )}
                                </>
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    variant="outlined"
                                    inputProps={{ tabIndex: "2" }}
                                    value={LastName}
                                    // onChange={(e) => setLastName(e.target.value)}
                                    onChange={handleLastName}
                                    style={{ margin: '8px 0px' }}
                                />
                                <>

                                    <TextField
                                        fullWidth
                                        label="Email Id"
                                        variant="outlined"
                                        inputProps={{ tabIndex: "3" }}
                                        value={SignUpEmail}
                                        // onChange={(e) => setSignUpEmail(e.target.value)}
                                        onChange={handleSignUpEmailChange}
                                        style={{ margin: '8px 0px' }}
                                    />
                                    {SignupEmailError && (
                                        <div style={{ color: "red", fontSize: "14px" }}>
                                            {SignupEmailError}
                                        </div>
                                    )}
                                </>

                                <>

                                    <div style={{
                                        position: "relative",
                                        width: "100%",
                                    }}
                                    >
                                        <TextField
                                            fullWidth
                                            type={ShowSigninPassword ? "text" : "password"}
                                            label="Password"
                                            variant="outlined"
                                            inputProps={{ tabIndex: "4" }}
                                            value={SignUpPassword}
                                            // onChange={(e) => setSignUpPassword(e.target.value)}
                                            onChange={handleSignUpPassword}
                                            style={{ margin: '8px 0px', }}
                                        />
                                        <span
                                            onClick={() => togglePasswordVisibility(ShowSigninPassword, setShowSigninPassword)}
                                            style={{
                                                cursor: "pointer",
                                                position: "absolute",
                                                top: "40%",
                                                right: "5%",
                                            }}
                                        >
                                            {ShowSigninPassword ? <FaEye /> : <FaEyeSlash />}
                                        </span>
                                    </div>
                                    {SignupPasswordError && (
                                        <div style={{ color: "red", fontSize: "14px" }}>
                                            {SignupPasswordError}
                                        </div>
                                    )}
                                </>
                                <>
                                    <div style={{
                                        position: "relative",
                                        width: "100%",

                                    }}>
                                        <TextField
                                            fullWidth
                                            type={ShowRetypePassword ? "text" : "password"}
                                            label="Retype Password"
                                            variant="outlined"
                                            inputProps={{ tabIndex: "5" }}
                                            value={RetypeSignUpPassword}
                                            // onChange={(e) => setRetypeSignUpPassword(e.target.value)}
                                            onChange={handleRetypeSignupPassword}
                                            style={{ margin: '8px 0px', }}
                                        />


                                        <span
                                            onClick={() => togglePasswordVisibility(ShowRetypePassword, setShowRetypePassword)}
                                            style={{
                                                cursor: "pointer",
                                                position: "absolute",
                                                alignItems: 'center',
                                                // border: '2px solid red',
                                                top: "40%",
                                                right: "5%",
                                            }}
                                        >
                                            {ShowRetypePassword ? <FaEye /> : <FaEyeSlash />}
                                        </span>
                                    </div>
                                    {RetypeSignupPasswordError && (
                                        <p style={{ color: "red", fontSize: "14px" }}>
                                            {RetypeSignupPasswordError}
                                        </p>
                                    )}
                                </>

                                <TextField
                                    fullWidth
                                    label="Referral or Voucher code ( Optional )"
                                    variant="outlined"
                                    inputProps={{ tabIndex: "6" }}
                                    value={Referral}
                                    onChange={(e) => setReferral(e.target.value)}
                                    style={{ margin: '8px 0px' }}
                                />


                                <FormControl component="fieldset" style={{ display: 'flex', alignItems: 'center', margin: '0px 5px' }} >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={PromotionMail}
                                                onChange={() => setPromotionMail(!PromotionMail)}
                                            />
                                        }
                                        label={
                                            <Typography style={{ fontSize: '17px', color: 'black', fontWeight: '100' }}>
                                                I'd like to recieve promotional emails.
                                            </Typography>
                                        } />
                                </FormControl>
                                <Button
                                    onClick={HandleSignUp}
                                    type="submit"
                                    style={{
                                        width: "95%",
                                        fontSize: "15px",
                                        color: "white",
                                        border: "1px solid #011015",
                                        backgroundColor: "#5CA7E0",
                                        border: "0px ",
                                        borderRadius: "10px",
                                        margin: '10px 0px ',
                                    }}
                                >
                                    AGREE AND SIGN UP
                                </Button>
                            </form>


                            <p style={{ fontSize: '15px', fontWeight: '100', letterSpacing: '.5px', margin: '10px', textAlign: "justify" }} >
                                By signing up for an digiesim account, I agree to the <a href='https://help.digiesim.com/hc/en-us/sections/26169454424081-The-small-print' target='_blank' style={{ fontWeight: '400', textDecoration: 'underline', color: 'black' }}> Terms & Conditions.</a> Learn more about how we use and protect your data in our <a
                                    href='https://help.digiesim.com/hc/en-us/sections/26169454424081-The-small-print' target='_blank' style={{ fontWeight: '400', textDecoration: 'underline', color: 'black' }}>Privacy Policy</a>.

                            </p>

                            {/* <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%', margin: '7px 0px' }} >
                                <hr style={{ border: '1px lightgrey solid', width: '30%' }} />
                                <p style={{
                                    color: "#8224e3",
                                    fontSize: "13px",
                                    color: 'black',
                                    fontWeight: '100'
                                }} >
                                    OR SIGNUP WITH
                                </p>
                                <hr style={{ border: '1px lightgrey solid', width: '30%' }} />
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', padding: '15px 0px' }} >
                                <img src={AppleLogo} className='logos' />
                                <img src={GoogleLogo} className='logos' />
                                <img src={FbLogo} className='logos' />
                            </div> */}

                        </div>
                    }
                </div>
            </div>

            {/* Modal for veryfing the OTP Mail */}

            <Modal
                open={OpenVerifyEmailModal}
            >
                <Box sx={ModalStyle2} className="modal-box">
                    <div
                        className="box2_1"
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    >
                        <H2
                            style={{
                                fontSize: "16px",
                                width: "100%",
                                fontWeight: "300",
                                margin: "10px 0px"
                            }}
                        >
                            A Verificatioin Email with OTP has been sent to your <br />
                            <span style={{ color: '#E25141', textDecoration: 'underline' }} >
                                {SwitchTab == "1" && email}
                                {SwitchTab == "2" && SignUpEmailForOtpVerify}
                            </span> ,
                            <br /> Please Verify it.
                        </H2>

                        <div
                            className="inputInfoBox "
                            style={{ width: "85%", marginBottom: "20px" }}
                        >
                            <OutlinedInput
                                autoFocus={true}
                                type="text"
                                inputProps={{ tabIndex: "1" }}
                                value={VerifyOtp}
                                onChange={(e) => setVerifyOtp(e.target.value)}
                                style={{ width: "100%", height: "40px" }}
                                placeholder="Enter the OTP here."
                            />

                            <H2
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "200",
                                    marginTop: '10px',
                                }}
                            >
                                {
                                    SwitchTab == "1" &&
                                    <OtpTimer seconds={59} minutes={2}
                                        resend={(emailId) => HandleResendOtp(email)}
                                        background={"#FFFFFF"}
                                        buttonColor={"#E25141"}
                                        ButtonText="Click to resend OTP" />
                                }

                                {
                                    SwitchTab == "2" &&
                                    <OtpTimer seconds={59} minutes={2}
                                        resend={(emailId) => HandleResendOtp(SignUpEmailForOtpVerify)}
                                        background={"#FFFFFF"}
                                        buttonColor={"#E25141"}
                                        ButtonText="Click to resend OTP" />
                                }

                            </H2>
                        </div>

                        <div>

                            {
                                SwitchTab == "1" &&
                                <Button
                                    onClick={(emailId) => HandleVerifyEmail(email)}
                                    style={{
                                        color: "green",
                                        marginRight: "20px",
                                    }}
                                    variant="outlined"
                                    color="inherit"
                                >
                                    Submit
                                </Button>
                            }

                            {
                                SwitchTab == "2" &&
                                <Button
                                    onClick={(emailId) => HandleVerifyEmail(SignUpEmailForOtpVerify)}
                                    style={{
                                        color: "green",
                                        marginRight: "20px",
                                    }}
                                    variant="outlined"
                                    color="inherit"
                                >
                                    Submit
                                </Button>
                            }

                            <Button
                                onClick={() => {
                                    setOpenVerifyEmailModal(false);
                                    setVerifyOtp("")
                                }}
                                style={{ color: "indianRed" }}
                                variant="outlined"
                                color="inherit"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>


        </div >
    )
}

export default Login;